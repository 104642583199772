import {
  FontFamily, FontSize, TextTransformOptions, Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"HelveticaNeue-Roman"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG40,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"HelveticaNeue-Bold"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.MED40,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"HelveticaNeue-Bold"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG40,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: '75px',
    },
    headerMobile: {
      size: '70px',
    },
  },
  [Components.WYSIWYG]: {
    header2: {
      size: '40px',
    },
    header3: {
      size: '30px',
    },
    header4: {
      size: '24px',
    },
    header5: {
      size: '20px',
    },
    header6: {
      size: '20px',
    },
  },
  /* [Components.DOR_SNOW_REPORT]: {
    body: {
      size: '16px',
    },
    bodyLarge: {
      size: '16px',
    },
    bodyLarger: {
      size: '16px',
    },
  }, */
};

// const HeaderTransform = TextTransformOptions.NO_TRANSFORM;

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
